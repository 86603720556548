import { withStyles } from '@material-ui/core'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'

export const StyledToggleButtonGroup = withStyles(theme => ({
  root: {
    border: `1px solid ${theme.palette.divider}`,
    color: 'var(--color-neutral-800)',
    fontWeight: 'bold',
    height: '2.6875rem',
    '&$selected': {
      backgroundColor: 'var(--color-neutral-800)',
      '&:hover': {
        backgroundColor: 'var(--color-grey-dark)'
      },
      '&& span': {
        color: 'var(--color-white)'
      }
    },
    '&$selected&$disabled': {
      backgroundColor: 'var(--color-grey)',
      '&& span': {
        color: 'rgb(0 0 0 / 65%)',
        opacity: '0.5'
      }
    },
    '&$disabled': {
      borderColor: 'var(--color-grey)',
      color: 'rgb(0 0 0 / 65%)'
    }
  },
  label: {
    fontSize: '1rem',
    textTransform: 'none'
  },
  grouped: {
    margin: theme.spacing(0.3),
    border: 'none',
    height: '2.3rem',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius
    }
  }
}))(ToggleButtonGroup)
